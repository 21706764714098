import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className='Footer'>
     <div className='Footer_child'>
      <div>
<p>Copyright © <small>Amanuel.</small></p>
<p>All Rights Reserved</p>
      </div>
      <div className='Footer_child_privacy'>
     <p>Term & Policy</p>
     <hr />
     <p> 
     Disclaimer
     </p>
      </div>
     </div>
    </div>
  )
}

export default Footer